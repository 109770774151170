import React from 'react';
import PropTypes from 'prop-types';
import _snakeCase from 'lodash/snakeCase';
import _orderBy from 'lodash/orderBy';
import _trimStart from 'lodash/trimStart';

import FlashMessage from './FlashMessage';

import Field from 'shared/Field';
import Input from 'shared/Input';
import Loader from 'shared/Loader';
import Modal from 'shared/Modal';
import QuoteSimple from 'shared/QuoteSimple';



import FormUtils from 'utils/Form';
import { isMobile } from 'utils/Browser';
import WebsiteQuoterApiUtils from 'utils/WebsiteQuoterApi';

import { Colors, FontSizes } from 'constants/Clementine';



class LeadModal extends React.Component {
  static propTypes = {
    fields: PropTypes.array,
    lead: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    quote: PropTypes.object,
    successMessage: PropTypes.string
  };

  constructor(props) {
    super(props);

    const state = {
      loading: false,
      submitted: false,
      success: false,
      errors: [],
      show_help_modal: false,
      help_content: null
    };

    props.fields.forEach(field => {
      const name = _snakeCase(field.label);
      const value = props.lead[name] || '';
      state[`lead_${name}`] = value;
    });

    this.state = state;
  }

  _handleFieldChange = (name, e) => {
    this.setState({
      [name]: _trimStart(e.target.value)
    });
  };

  _handleHelpClick = help_content => {
    this.setState({
      help_content,
      show_help_modal: true
    });
  };

  _handleConfirm = () => {
    const errors = [];
    const updated_lead = Object.assign({}, this.props.lead);

    updated_lead.quote_id = this.props.quote.id;

    // Right now the Lead model in Ironsides has fixed lead attributes
    // If we want to expand the customizable fields functionalty, then we'll need to
    // store the lead data as a json object on an attribute
    this.props.fields.forEach(field => {
      const name = `lead_${_snakeCase(field.label)}`;
      const value = this.state[name] || '';

      if (field.is_required && !value) {
        errors.push(field.id);
      }

      if (field.is_required && field.field_type === 'email' && !FormUtils._validateEmail(value)) {
        errors.push(field.id);
      }

      updated_lead[name.replace('lead_', '')] = value;
    });

    if (errors.length) {
      this.setState({ errors });
    } else {
      this.setState({
        loading: true,
        errors: []
      });

      if (updated_lead.id) {
        WebsiteQuoterApiUtils.updateLead(updated_lead)
          .then(() => {
            this.setState({
              submitted: true,
              loading: false,
              success: true
            });
          })
          .catch(() => {
            this.setState({
              submitted: true,
              loading: false
            });
          });
      } else {
        WebsiteQuoterApiUtils.saveLead(updated_lead)
          .then(() => {
            this.setState({
              submitted: true,
              loading: false,
              success: true
            });
          })
          .catch(() => {
            this.setState({
              submitted: true,
              loading: false
            });
          });
      }
    }
  };

  handleRetry = () => {
    this.setState({
      submitted: false,
      success: false,
    })
  }

  render() {
    const styles = this.styles();
    const buttons = [
      {
        children: 'Cancel',
        onClick: this.props.onClose,
        color: Colors.GRAY.hex,
        style: { marginLeft: 'auto' }
      },
      {
        children: 'Submit',
        onClick: this._handleConfirm,
        style: { marginLeft: 10 }
      }
    ];

    const failedButtons = [
      {
        children: 'Cancel',
        onClick: this.props.onClose,
        color: Colors.GRAY.hex,
        style: { marginLeft: 'auto' }
      },
      {
        children: 'Retry',
        onClick: this.handleRetry,
        style: { marginLeft: 10 }
      }
    ];

    const successButton = [{
      children: 'Close',
      onClick: this.props.onClose,
      color: Colors.GRAY.hex,
      style: { marginLeft: 'auto' }
    },]
    

    return (
      <>
        {!this.state.success && !this.state.submitted &&
          <Modal buttons={this.state.success ? null : buttons} maxWidth={800} onClose={this.props.onClose} title={this.state.success ? null : 'Complete Your Information'}>
            {this.state.success ? (
              <div style={styles.content}>
                <div style={styles.response}>
                  <i className='mdi mdi-checkbox-marked-circle-outline' style={styles.success_icon} />
                  {this.props.successMessage || 'Success! Your request has been submitted.'}
                </div>
              </div>
            ) : (
              <React.Fragment>
                {this.state.loading ? (
                  <Loader />
                ) : (
                  <div style={styles.content}>
                    {this.state.show_help_modal ? (
                      <Modal
                        onClose={() => {
                          this.setState({ show_help_modal: false, help_content: null });
                        }}
                      >
                        {this.state.help_content}
                      </Modal>
                    ) : null}

                    {this.state.errors.length ? (
                      <FlashMessage isPinned={true} style={styles.flash_message}>
                        Please fill out all required fields.
                      </FlashMessage>
                    ) : null}

                    <QuoteSimple quote={this.props.quote} />
                    <br />
                    <br />

                    <div style={styles.fields}>
                      {_orderBy(this.props.fields, 'order').map(field => {
                        const name = `lead_${_snakeCase(field.label)}`;
                        if (field.is_visible) {
                          return (
                            <Field key={field.id} label={field.label} style={{ width: isMobile ? '100%' : `${field.width || 100}%`, padding: '0px 5px' }} tooltip={field.help_content}>
                              <Input
                                format={field.field_type !== 'text' ? field.field_type : null}
                                invalid={this.state.errors.includes(field.id)}
                                onChange={this._handleFieldChange.bind(null, name)}
                                required={field.is_required}
                                value={this.state[name] || ''}
                              />
                            </Field>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
          </Modal>
        }
        {this.state.success && this.state.submitted && 
          <Modal
            onClose={this.props.onClose}
            buttons={successButton}
            title='Success'
            maxWidth={600}
          >
            <div style={styles.copyContainer}>
              <p style={{ fontSize: 16}}>Successfully submitted.</p>
            </div>
          </Modal>
        }
        {!this.state.success && this.state.submitted &&
            <Modal
              onClose={this.props.onClose}
              buttons={failedButtons}
              title='Failed'
              maxWidth={600}
            >
              <div style={styles.copyContainer}>
                <p style={{fontSize: 16}}>Something went wrong.</p>
                <p>Your quote information is having trouble being submitted.</p>
                <p>Please try again.</p>
              </div>
            </Modal>
        }
      </>
    );
  
      
  }

  styles = () => {
    return {
      content: {
        padding: 40
      },
      copyContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "200px",
        width: "600px"
      },
      flash_message: {
        top: 60
      },
      header: {
        fontSize: FontSizes.LARGE,
        marginBottom: 15
      },
      fields: {
        display: 'flex',
        flexWrap: 'wrap'
      },
      response: {
        fontSize: FontSizes.LARGE,
        textAlign: 'center',
        lineHeight: '1.4em'
      },
      success_icon: {
        fontSize: 60,
        display: 'block',
        margin: '0 auto',
        color: Colors.GREEN.hex,
        marginBottom: 15
      }
    };
  };
}

export default LeadModal;
